@import "nodeca.core/client/admin/core/_variables"


.ping-ticker
  display inline-block
  width .5rem
  height .5rem
  background-color #888
  opacity .2

.ping-ticker.active
  transition opacity 1s ease
  opacity 1

.dashboard-offline-toggle
  .btn
    margin-left 0

.dashboard-offline-toggle__on
  display none

.dashboard-offline-toggle__m-offline
  .dashboard-offline-toggle__on
    display inline-block
  .dashboard-offline-toggle__off
    display none
