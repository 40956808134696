//
// Settings
//


@import "nodeca.core/client/admin/core/_variables"


// Dim `inherited` setting state
//
.form-group.inherited
  .col-form-label
    opacity .4
  .control-value
    opacity .4

// Dim `forced` settings state
//
.form-group.forced
  .switch
    box-shadow: 0 0 0 1px $gray-700 inset

// Add hover to settings line
.form-group-hover:hover
  background-color $gray-100
