#io-progress
  position fixed
  top 50px
  left 50%
  z-index 2000

  border 1px solid (#fcf8e3 - 50%)
  width 140px
  margin-left -70px
  box-sizing border-box

  display none
