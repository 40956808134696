.dialogs-search__item
  cursor pointer

  & > td
    // nick field is slightly bigger because of dropdown,
    // so default 'top' aligns the date higher on the screen
    vertical-align baseline

.dialogs-search__text
  display none

.dialogs-search__m-item-expanded + .dialogs-search__text
  display table-row // reset to default

.dialogs-search__text
  .markup
    color #6c757d // gray-600 or text-secondary color
    margin-left 4rem
