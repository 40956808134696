@import "nodeca.core/client/admin/core/_variables"

// Page breadcrumbs
//
//.breadcrumbs

.breadcrumbs
  padding 0
  margin 0 0 .5rem
  font-size $font-size-sm
  color $gray-600
  shortable()

.breadcrumbs__item
  display inline-block
  position relative
  margin-right 1.2em
  &:after
    position absolute
    content '/'
    right -0.8em

a.breadcrumbs__link
  color inherit
