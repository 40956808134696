/**
 *= require ./src/css/nodeca-codes
 **/

@font-face {
  font-family: 'nodeca';
  src: url(/assets/nodeca-bdfab2852acdb88531ddd06316fd41c8.woff2) format('woff2'),
       url(/assets/nodeca-b40df8a9770be38e5030a8f7c505f160.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  font-family: "nodeca";
  speak: none;
  /*text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);*/

  display: inline-block;
  text-align: center;
  line-height: 1em;
  position: relative;

  font-style: normal !important;
  font-weight: normal !important;
}

.icon-space-after:before {
  margin-right: .3em;
}

.icon-space-before {
  margin-left: .3em;
}

.icon-dimmed:before {
  opacity: 0.7;
}

.icon-lg:before {
  font-size: 120%;
  top: 1px;
}
