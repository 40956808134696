@import "nodeca.core/client/admin/core/_variables"


.switch
  width 120px
  height 25px
  padding 2px
  position relative
  margin-top 5px
  margin-bottom 7px

  background white
  border-radius 3px
  box-shadow: inset 0 0px 1px rgba(0, 0, 0, 0.2)

  label
    display block
    width 100%
    height 100%
    position relative

    text-align center
    font-weight bold
    line-height 23px

    & > i
      transition: all 0.3s ease-out;
      border-radius 3px

      position absolute
      top 0
      left 50%
      z-index 1

      width 50%
      height 100%

      background-color $brand-danger + 50%
      background-image linear-gradient(top, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0))
      box-shadow inset 0 1px 1px rgba(255, 255, 255, 0.5), inset 0 0 1px rgba(0, 0, 0, 0.5)

    &:before
      position absolute
      z-index 2
      width 50%
      height 100%
      content attr(data-yes)
      left 0

      color $gray-600
      text-shadow 0 1px 0 white

    &:after
      position absolute
      z-index 2
      width 50%
      height 100%
      content attr(data-no)
      right 0

      color $brand-danger
      text-shadow 0 1px 0 rgba(255, 255, 255, 0.5)

  input
    position absolute
    width 100%
    height 100%
    z-index 100
    opacity 0
    cursor pointer

    &:checked ~ label
      > i
        left 0
        background-color $brand-success + 40%
      &:before
        color $brand-success - 30%
        text-shadow 0 1px 0 rgba(255, 255, 255, 0.5)
      &:after
        color $gray-600
        text-shadow 0 1px 0 white
