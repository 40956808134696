@import "nodeca.core/client/admin/core/_variables"

.members-search__loading-next
  padding 3rem
  font-size 2rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
