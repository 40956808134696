//
// Hide original link in image
//
.attach__link-control
  display none !important


//
// Hide quote controls
//
.quote__controls
  display none !important


//
// Always show spoilers as opened
//
.spoiler__content
  display block !important

.spoiler__icon-collapse
  display none !important

.spoiler__icon-expand
  display none !important
