@import "../../_variables"

// appearance animation
//
@keyframes notification
  0%
    transform rotateY(-90deg)
    opacity 0
  70%
    transform rotateY(20deg)
    opacity .8
  90%
    transform rotateY(-10deg)
    opacity 1
  100%
    transform rotateY(-0deg)
    opacity: 1

// notifications container
//
.notifications
  position fixed
  right 0rem
  top 1rem
  z-index 9999
  // for narrow screens
  margin 0 1rem
  width 100%
  max-width 300px

// generic notice styles & animation
//
.notification
  position: relative;

  border-left 3px solid transparent
  box-shadow 0px 2px 8px #a0a0a0

  margin-bottom .5rem

  //max-width 300px
  //width 100%
  max-height (alert_notice_max_height=200px)
  overflow auto
  text-shadow none

  transition all .5s linear

  a
    font-weight bold
    text-decoration underline

  // OFF state
  &.fade
    opacity 0
    // height animation hack
    max-height 0
    overflow hidden

    // ON state
    &.show
      opacity 1
      animation notification .75s linear
      // just return original value, if we use fade
      overflow auto
      max-height alert_notice_max_height

.notification-danger
  background-color $brand-danger
  border-left-color $brand-danger
  color white
  a
    color white

.notification-info
  background-color white
  border-left-color $brand-primary
  color $brand-primary - 10%
  a
    color $brand-primary - 10%

.notification-close-white
  display none

.notification-danger
  .notification-close-default
    display none

  .notification-close-white
    display block


.notifications-container
  position fixed
  top 0
  width 100%
  z-index 9999
  .notifications
    position absolute
    top 63px
    margin 0
