@import "nodeca.core/client/admin/core/_variables"

body
  padding-top 70px

////////////////////////////////////////////////////////////////////////////////
//
// Disable responsiveness for small screens
//

.container-fluid
  max-width 1200px

////////////////////////////////////////////////////////////////////////////////

//
// Simple 2-columns layout
//
.sidebar-nav
  width 180px

.show-sidebar
  .main
    margin-left 210px


// Show navbar dropdowns on hover, instead of click
//
.navbar__hovered
  .dropdown:hover > .dropdown-menu
    height auto
    opacity 1

  // - hack `display` to make opacity work
  .dropdown > .dropdown-menu
    display block
    height 0
    overflow hidden
    opacity 0
    transition opacity 0.3s ease


// "No JS" message styles
//
.nojs-alert
.nocookies-alert
  display none
  left 50%
  width 500px
  margin-left -250px
  text-align center
  position fixed
  top 90px
  z-index: 1000

.nojs-alert
  display block

.no-cookies .nocookies
  display block
