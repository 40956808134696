@import "nodeca.core/client/common/_variables"

.ausers-blacklist__test
  margin-bottom 1rem

  & > .input-group
    width 100%

@keyframes ausers-blacklist-flash-animation {
  from  { background: $decorator-color;  }
  to    { background: none; }
}

.ausers-blacklist__list
  display block
  overflow-x scroll

.ausers-blacklist__m-item-highlight
  animation ausers-blacklist-flash-animation 1.5s
