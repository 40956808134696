// maintain aspect ratio of 4:3 for the map, see
// https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css
//
.location-show-dlg__map-container
  width 100%
  padding-bottom 56.25%
  position relative

  // same background as for .leaflet-container,
  // in case styles for leaflet aren't loaded yet
  background #ddd

.location-show-dlg__map
  position absolute
  top 0
  bottom 0
  left 0
  right 0
