@import "nodeca.core/client/admin/core/_variables"

// Sidebar
//
.sidebar-menu
  display none
  position fixed
  top 70px
  width 180px
  padding .5rem 0
  border-radius 4px
  background-color $gray-100

  .nav-link
    display block
    padding .5rem 1rem
    color $gray-700

  .nav-item._active
    background-color $gray-200

.show-sidebar
  .sidebar-menu
    display block
