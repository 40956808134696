@import "nodeca.core/client/admin/core/_variables"

.rebuild-widget
  margin-bottom 2rem

.rebuild-widget__title
  font-size 1rem
  line-height 1.1em
  margin-bottom .5rem

.rebuild-widget__progress
  margin-bottom 1rem

.rebuild-widget
  .btn + .btn
    margin-left 0 !important
