.dialogs-search__toolbar
  margin-bottom 1rem
  display flex
  justify-content space-between

.dialogs-search__expand-btn
  display block

.dialogs-search__collapse-btn
  display none

.dialogs-search__m-expand-all
  .dialogs-search__expand-btn
    display none

  .dialogs-search__collapse-btn
    display block
