// Attachment generic classes
//

@import "nodeca.core/client/common/_variables"

.attach-img
  background-color $body-bg-secondary

.attach__m-md, .attach__m-orig
  min-width 20px
  min-height 20px
  max-width 100%
  display inline-block
  border-radius 2px
  position relative

  & > img
    max-width 100%
    border-radius 2px

.attach__spacer + img
  position absolute
  top 0
  left 0

// Link to the original image
a.attach__link-control
  display block
  transition .2s ease .1s
  position absolute
  bottom 3px
  right 3px
  opacity 0
  height 30px
  line-height 30px
  width 30px
  text-align center
  color white
  background-color rgba(0, 0, 0, .3)
  border-radius 3px


.attach:hover .attach__link-control
  opacity 1

.attach__spacer
  display block

//
// Thumbnail container (link)
//

$thumb-image-width  = '$$ N.config.users.uploads.resize.sm.max_width || N.config.users.uploads.resize.sm.width $$'px
$thumb-image-height = '$$ N.config.users.uploads.resize.sm.max_height || N.config.users.uploads.resize.sm.height $$'px

// 170x150 -> 119x105
$down-scale-sm = 1.428571429
// 170x150 -> 85x75
$down-scale-xs = 1.7

$thumb-image-width-sm  = round($thumb-image-width  / $down-scale-sm)
$thumb-image-height-sm = round($thumb-image-height / $down-scale-sm)
$thumb-image-width-xs  = round($thumb-image-width  / $down-scale-xs)
$thumb-image-height-xs = round($thumb-image-height / $down-scale-xs)

.attach__m-sm
  width $thumb-image-width
  height $thumb-image-height
  display inline-flex
  align-items center
  justify-content center
  position relative
  border-radius 2px
  vertical-align middle
  box-shadow 0 0 1px ($body-bg-secondary - 10%)
  background-color $body-bg-secondary
  margin-right .7rem
  margin-bottom .7rem

  .attach__image
    max-width $thumb-image-width
    max-height $thumb-image-height
    display inline-block
    vertical-align middle
    text-align center

  // Text content, when no image
  .attach__content
    display inline-block
    vertical-align middle
    max-width 100%
    padding 0 1em
    shortable()

  // Icon marker
  .attach__icon
    position absolute
    font-size $font-size-lg
    line-height 1em
    top .5em
    left .5em
    color $body-color-secondary + 30%

//
// Responsive rules (reduce size)
//

@media (max-width: $grid-breakpoints.md - 1)
  .attach__m-sm
    width $thumb-image-width-sm
    height $thumb-image-height-sm

    .attach__image
      max-width $thumb-image-width-sm
      max-height $thumb-image-height-sm

// For portrait mobile
@media (max-width: $grid-breakpoints.sm - 1)
  .attach__m-sm
    width $thumb-image-width-xs
    height $thumb-image-height-xs

    .attach__image
      max-width $thumb-image-width-xs
      max-height $thumb-image-height-xs

.attach__m-broken
  background-image url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3e%3cpath fill='%23aeb9bf' d='M918.4 149.5h-349c-14.1 0-26.2 12.1-26.2 26.2v118.9c0 15.9 13.5 25 22 33.6 11.4 11.4 78.7 77.4 83 83.8L483.4 577.6c-22 22-28.4 31.1-2.3 57.1l69.5 69.5c14.4 14.3 11.2 8.3 11.2 35.1 0 14.8-1.4 82.8 1.2 91.4 3.4 10.9 12.8 19.8 26.5 19.8h328.9c13 0 26.2-11.3 26.2-23.2V172.7c.1-10.2-10.7-23.2-26.2-23.2M597.3 282.3v-78.7h294.9v592.9H615.8v-89.6c0-19.4-20.8-34-39.8-52.9l-46.7-47.5c7.2-4.8 146.7-145.9 164.1-163.3 14.1-14.1 19.7-16.4 19.7-34.4 0-12.5-18.4-26.9-25.9-34.4l-75.7-75.7c-3.3-3.2-14.2-11.7-14.2-16.4z'/%3e%3cpath fill='%23aeb9bf' d='M391.9 149.5H81.6c-15.5 0-26.2 13-26.2 23.2v654.7c0 11.9 13.3 23.2 26.2 23.2h328.9c19.2 0 27.8-14.2 27.8-34v-105c0-18.7-5.5-23.3-15.1-32.8l-71.4-72.2 176.3-175.7c9.4-12.5 10.3-25.3-.6-37l-9.2-9.4c-1.5-1.4-1.7-1.6-3.1-3.1l-55.5-55.7c-.2-.2-.5-.6-.8-.8-.2-.2-.6-.5-.8-.8-2.5-2.5-3.7-3.6-6.2-6.2l-24.7-24.7c-5.4-5.1-9-7.1-9.2-17.1-.7-31.2 1.6-73.6.2-102.1-.6-14-11.6-24.5-26.3-24.5m-26.2 54v95.7c0 13.5 39.3 47.8 49.8 58.3l53.7 54.4c-1.4 5.3-39 41.4-45.9 48.3L305.8 577.6c-10.8 10.8-18.9 14-18.9 32 0 13.4 36.5 45 46.7 55.2 6.5 6.5 50.6 48 50.6 52.9v78.7H107.8V203.5h257.9z'/%3e%3c/svg%3e")
  background-size 30%
  background-repeat no-repeat
  background-position center center
