.section-moderator-list
  list-style: none

.section-moderator-override-none
  color: #888888

.section-moderator-override-some-own
  color: #FFAA00

.section-moderator-override-some-inherited
  color: #FFCC88

.section-moderator-override-every-own
  color: #FF0000

.section-moderator-override-every-inherited
  color: #FF8888
