.section-usergroup-list
  list-style: none

.section-usergroup-override-none
  color: #888888

.section-usergroup-override-some-own
  color: #FFAA00

.section-usergroup-override-some-inherited
  color: #FFCC88

.section-usergroup-override-every-own
  color: #FF0000

.section-usergroup-override-every-inherited
  color: #FF8888
