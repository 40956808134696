@import "nodeca.core/client/common/_variables"

.btn + .btn
  margin-left .4rem

.btn-square
  padding-left 0 !important
  padding-right 0 !important
  min-width 2.375em // 38px

.btn-sm.btn-square
  min-width 2.0em // 28px

.btn-xs.btn-square
  min-width 1.917em // 23px


.dropdown-toggle.btn-square:after
  margin-left 0

.dropdown-toggle.icon:after
  border none
  width 0
  margin 0

// Increase minimal possible height for multiline text inputs
.controls textarea.form-control
  min-height 12rem

// Additional tabs style
.nav-tabs-underlined
  margin-bottom 1rem !important

  .nav-item
    display inline

  .nav-item + .nav-item
    margin-left .7em

  .nav-link
    padding .5em .5em
    border-bottom 3px solid transparent

    &.active
      border-bottom 3px solid $brand-primary

  .nav-link + .nav-link
    margin-left .7em
