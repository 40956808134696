// Spoiler style definitions

@import "nodeca.core/client/common/_variables"

.spoiler
  border 1px solid $quote-border-color
  border-left .1875rem solid $quote-decorator-color
  margin-bottom 1rem

.spoiler__title
  cursor pointer
  background-color $quote-bg-color
  font-size $font-size-sm
  font-weight bold
  padding .5rem 1rem

.spoiler__inner
  overflow hidden

.spoiler__content
  padding 1rem 1rem 0
  margin-bottom -.5rem
  display none

// writing explicit paths to the content here, because
// ".spoiler__m-open .spoiler__content" selector would match
// a closed spoiler inside of an open one (same with icons below)
//
.spoiler__m-open > .spoiler__inner > .spoiler__content
  display block

.spoiler__icon-collapse
  display none

.spoiler__m-open > .spoiler__title
  .spoiler__icon-collapse
    display inline
  .spoiler__icon-expand
    display none

// Always open spoiler in editor in preview
//
.mdedit__preview .spoiler__content
  margin-top 0
